/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/assets/styles/style.scss'

import Provider from './src/context/provider';

export const wrapRootElement = Provider;
