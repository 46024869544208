exports.components = {
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-equipo-jsx": () => import("./../../../src/pages/equipo.jsx" /* webpackChunkName: "component---src-pages-equipo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-laboratorios-interno-jsx": () => import("./../../../src/pages/laboratorios-interno.jsx" /* webpackChunkName: "component---src-pages-laboratorios-interno-jsx" */),
  "component---src-pages-laboratorios-jsx": () => import("./../../../src/pages/laboratorios.jsx" /* webpackChunkName: "component---src-pages-laboratorios-jsx" */),
  "component---src-pages-noticia-interna-jsx": () => import("./../../../src/pages/noticia-interna.jsx" /* webpackChunkName: "component---src-pages-noticia-interna-jsx" */),
  "component---src-pages-noticias-jsx": () => import("./../../../src/pages/noticias.jsx" /* webpackChunkName: "component---src-pages-noticias-jsx" */)
}

