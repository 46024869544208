import React, { useState, useEffect } from 'react';

export const AppContext = React.createContext();

const Provider = props => {
  const [isDark, setTheme] = useState(() => {
    const savedTheme = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('theme'));
    return savedTheme || false;
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const colorTheme = isDark ? 'dark' : 'light';
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark');
      root.classList.add(colorTheme);

      localStorage.setItem('theme', isDark);
    }
  }, [isDark]);

  const changeTheme = () => {
    setTheme(!isDark);
  };

  // Generar una ID única usando una variable que no cambie entre el servidor y el cliente
  const disclosureButtonId = 'headlessui-disclosure-button';

  return (
    <AppContext.Provider value={{
      isDark,
      changeTheme
    }}>
      {typeof window !== 'undefined' && (
        <div id={disclosureButtonId}>
          {props.children}
        </div>
      )}
    </AppContext.Provider>
  );
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
